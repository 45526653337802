import photo from './polaroid.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={photo} className="App-logo" alt="logo" />
        <br />
        <p>
          Coming soon™
        </p>
      </header>
    </div>
  );
}

export default App;
